.App {
    text-align: center;
}

.App-header {
    /* background-color: #282c34; */
    /* color: white; */
    background-color: #fff3e5;
    color: #282c34;

    font-size: calc(50px + 2vmin);
    font-family: monospace;
}